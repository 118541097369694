import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishLanguageIcon from "@images/english-language-icon.svg";
import italianLanguageIcon from "@images/italian-language-icon.svg";
i18n
  .use(initReactI18next)
  .init({
    resources: {
        en: {
          translation: {
            navBarMenuVoices: [
              {
                name: "Home",
              },
              {
                name: "Product",
              },
              {
                name: "Blog",
              },
              {
                name: "Contacts",
              },
              {
                name: "Prices",
              }
            ],
            changeLanguage: "Change language",
            languages: [
              {
                language: "Italian",
                lng: "it",
                icon: italianLanguageIcon
              },
              {
                language: "English",
                lng: "en",
                icon: englishLanguageIcon
              }
            ],
            translateRoutes: [
              // To avoid the redirect. TODO: Solve in the future
              {
                it: 'home',
                en: 'home'
              },
              {
                it: 'prodotto',
                en: 'product'
              },
              {
                it: 'contatti',
                en: 'contacts'
              },
              {
                it: 'prezzi',
                en: 'prices'
              },
              {
                it: 'aiTools',
                en: 'aiTools'
              }
            ],
            navBarButton: {
              login: "Login",
              onBoarding: "Join the Club",
              dropDownMenu: "Drop down menu",
              dropDownMenuLinks: [
                {
                  name: "Free AI Tools",
                  link: "aiTools"
                }
              ]
            },
            contactsPageTexts: {
              preTitle: "Let's talk, contact us!",
              title: "Is Restaurants Club really for you?",
              paragraphAfterTitle: "Let's find out together! Send an email, chat, or fill out the form: we are everywhere!",
              titleParagraph1: "Send us an email",
              paragraph1: "We are available from Monday to Friday",
              linkParagraph1: "info@restaurants.club",
              titleParagraph2: "Chat with Ciccio",
              paragraph2: "Our bot solves your doubts h24",
              linkParagraph2: "Start chatting",
              titleParagraph3: "Come to the office",
              paragraph3: "Visit us whenever you want",
              linkParagraph3: "Via Santa Maria 25, 56126 Pisa",
              titleParagraph4: "Call us",
              paragraph4: "From Monday to Friday 9 AM to 6 PM"
            },            
            heroProductsSectionTexts: {
              bannerNews: "News!",
              paragraphBanner: "We have a new user interface, even simpler",
              title: "Marketing done right, all by yourself",
              paragraphPart1: "Your restaurant is the talk of the town without hiring freelancers or agencies. Restaurants Club is the software that lets you do marketing completely on your own! Keep reading if you like the idea.",
              button: "Start now",
              button2: "Learn more"
            },
            weKnowItSectionTexts: {
              title: "We Know It",
              titleParagraph1: "You can't split yourself in four",
              paragraph1: "You’re already a restaurateur, you can’t also be a Social Media Manager, a Digital Marketer, and a PR Specialist. You need to do marketing effectively without wasting time.",
              titleParagraph2: "Who knows your restaurant better than you?",
              paragraph2: "The challenges you've overcome, the sources of inspiration: who better than you can tell the story of your restaurant? That’s why an agency can’t do it for you!",
              titleParagraph3: "You can’t only work on weekends",
              paragraph3: "FIPE says that restaurateurs make 62% of their revenue between Friday and Saturday. With Restaurants Club, you attract customers every day. All year round!"
            },
            forRestaurateursSectionTexts: {
              title: "By restaurateurs for restaurateurs",
              titleParagraph1: "Years of feedback and opinions",
              paragraph1: "It's been a few years since we started this journey, and our clients have given us a lot of feedback, which we have continuously used to update the software.",
              titleParagraph2: "They came to visit us right at home",
              paragraph2: "Well, almost! We opened the doors of our company to Matteo and Daniele, owners of two very different restaurants, who actively contributed to the creation of what is now online!"
            },
            featuresSectionTexts: {
              title: "How to make new faces become loyal",
              titleParagraph1: "Website",
              paragraph1: "Get a website in just a few hours, already optimized to appear among the top results. Not enough? You can modify it yourself!",
              list1Item1: "Digital menu that you can update in no time",
              list1Item2: "Edit texts and images independently",
              list1Item3: "Optimization to climb search engines",
              titleParagraph2: "Advertising",
              paragraph2: "Effective advertising is complicated if you're not an expert. With Restaurants Club, you can launch ads in three clicks. How? We handle the hard part.",
              list2Item1: "Bring only your ideal customers to the restaurant",
              list2Item2: "Rely on the effectiveness of optimized campaigns",
              list2Item3: "Keep your marketing under control",
              titleParagraph3: "Social media",
              paragraph3: "Not enough time to post? With Restaurants Club, you can rely on hundreds of graphic templates that you can customize however you want. The real gem? The automatic text generator.",
              list3Item1: "Customizable graphic templates",
              list3Item2: "Suggested captions for posts",
              list3Item3: "Calendar to schedule posts"
            },
            reviewsSectionTexts: {
              review1: "A very dynamic website with a customer service center that works and is especially attentive to the customer's needs... Highly recommended for those who want to work in the restaurant sector and solve any situation in real time.",
              name1: "Pasquale",
              review2: "From the custom-made website to the program that allows you to update the menu in real time, create and develop marketing as you please, but fortunately with the constant advice and consultancy of a well-prepared staff. Definitely my best experience with website and advertising promoters.",
              name2: "Cosimo",
              review3: "A well-designed and impressive website for the restaurant industry. The staff is always available for any need. Highly recommended.",
              name3: "Paolo Truglio",
              review4: "Fantastic experience, useful and clear! Engaging and captivating. The Restaurants Club staff... exceptional!",
              name4: "Luciano Metastasio",
              review5: "A company made up of young, talented people who are always up to date! We're having a great experience with them, from creating the website, to advertising campaigns, to constant contact. They are always available for any doubt or proposal, always ready to find a way to meet every request!",
              name5: "Rigels Turkeshi",
              review6: "Restaurants Club has been a great discovery, managed excellently by many capable and eager young people who want to grow and help others grow. The service is always expanding, full of new features aimed at improving an already comprehensive service, yet they are still ready to listen to every customer need...",
              name6: "Emanuele, Primo Pasta e Street food",
              review7: "Restaurants Club as a whole made it possible to meet every one of my social media needs, not only starting everything up but also ensuring that the staff takes care of every aspect. They are truly the epitome of excellence, availability, and kindness, which are the fundamental pillars of any work environment...",
              name7: "Federica La mantia",
              review8: "Professionalism and kindness. This platform is helping us increase our work by reaching more customers. We are very satisfied with Restaurants Club. Francesca, our representative, is serious and competent. Her guidance makes managing the website much easier than we expected.",
              name8: "Andy Perini",
              review9: "This restaurant marketing platform is simple to use, thanks also to the fantastic team that supports you in every aspect of the campaign. With intuitive tools and reliable customer support, it's the ideal choice for promoting your restaurant. Highly recommended!",
              name9: "Monique",
              review10: "A well-designed and intuitive website, perfect for those in the restaurant industry. The platform offers a wide range of useful tools to effectively manage your business. Additionally, the customer support is always available and highly knowledgeable, ready to assist at any time. It's a great ally for those looking to optimize their operations and enhance the customer experience!",
              name10: "Giovanni"
            },
            faqSectionTexts: {
              title: "Frequently Asked Questions",
              question1: "What is Restaurants Club?",
              answer1: "Restaurants Club is a software that allows restaurateurs to fully manage the online presence of their restaurant. In a simple and effective way, it allows you to create an optimized website, launch marketing campaigns in just a few clicks, manage social channels, reservations, and provide a smooth delivery service. In short, Restaurants Club does the work of many: website, advertising, table management, and delivery. Everything a restaurant needs for success, all in one tool.",
              question2: "How much does Restaurants Club cost?",
              answer2: "The cost of Restaurants Club varies based on the monthly or annual commitment and the number of extra Modules chosen.",
              question3: "What is advertising credit? How can I use it?",
              answer3: "Advertising credit is monetary credit that can be used to run online advertising campaigns on social platforms like Facebook and Instagram. Campaign management and launches must be done within the Restaurants Club platform to benefit from the credit. The advertising credit included in your subscription must always be used before the contract expires. It will be granted upon activation of the subscription and, in the case of an annual subscription, month by month.",
              question4: "What is the payment frequency?",
              answer4: "Payments are always monthly, even in the case of an annual commitment.",
              question5: "What payment methods do you accept?",
              answer5: "Accepted payment methods: Credit/debit cards via Stripe account, Direct Debit (RID), or Bank Transfer.",
              titleBlueParagraph: "Hooray for software powered by people!",
              blueParagraph: "Behind our software are Francesca, Pablo, Andrea, Marco, and many others. If you need help, one of us is ready to assist you step by step. Even at night, with support via WhatsApp."
            },            
            heroHeaderSectionTexts: {
              title: "Marketing for the restaurant industry",
              paragraphPart1: "The intuitive software that boosts your business. Strategic consulting included for efficient and independent management. Start now on your own.",
              privacyPart1: "We handle your data with care.",
              privacyPart2: "See the Privacy policy.",
              privacyLink: "Privacy policy",
              button: "Start now",
              button2: "Learn more",
              paragraphPart1mobile: "The intuitive software that boosts your business. Strategic consulting included. Start now on your own."
            },
            socialProofSection: {
              title: "Chosen by the best restaurants throughout Italy",
              titlemobile: "Chosen by the best restaurants"
            },
            socialProofSection2: {
              title: "See what they say"
            },
            featuresSectionTextsFirstPart: {
              title: "Optimize social media activity with artificial intelligence",
              paragraph: "Reach interested customers and optimize your online presence with smart and intuitive tools.",
              firstFeature: "Optimized Facebook and Instagram sponsored posts",
              secondFeature: "Captivating graphics for advertising",
              thirdFeature: "Create social posts with Artificial Intelligence",
              button: "Discover the details"
            },
            featuresSectionTextsSecondPart: {
              title: "Manage menus, reservations, and online orders from your website",
              paragraph: "From your website, your customers can book, order, and browse through your digital menu. Domain and Hosting included in subscription.",
              firstFeature: "Digital menu with QR Code",
              secondFeature: "Online reservations without commissions",
              thirdFeature: "Delivery, Takeaway, E-commerce without commissions",
              button: "Discover the details"
            },
            featuresSectionTextsThirdPart: {
              title: "Maximize the value of customer data",
              paragraph: "Enhance every interaction with your customers: leverage the power of data to offer personalized experiences.",
              firstFeature: "Customer details connected with website and newsletter system",
              secondFeature: "Import and export customer data in CSV files",
              thirdFeature: "Automatic emails for reminders, reviews, purchases, etc.",
              button: "Discover the details"
            },
            testimonialSectionTexts: {
              review: "“This platform is helping us increase our business by reaching more customers. We are very satisfied with Restaurants Club.”",
              reviewmobile: "“Restaurants Club is helping us increase our business by reaching more customers. We are very satisfied.”",
              reviewer: "Andy Perini",
              reviewerSpecification: "Owner of Andy Pizza, Pizzeria in the province of Rome"
            },
            reviewSectionTexts: {
              review: "“Social program, publications, reservations, and orders are all really well managed but above all having the possibility of an e-commerce and being able to input each customer into their own database are fundamental nowadays to grow our businesses.”",
              reviewer: "_Emanuele Caiazza",
              reviewerSpecification: "Owner of Primo Pasta, restaurant in Pisa"
            },
            socialSectionTexts: {
              title: "Reach customers interested in your place with sponsored posts on social media",
              titleMobile: "Reach interested people with sponsored posts",
              subtitle: "Our most loved feature!",
              paragraphPart: "Create effective ads in a few clicks: choose the user segment you want to engage, select or upload an image, choose the description and budget from optimized suggestions using artificial intelligence",
              paragraphPartMobile: "Effective ads in one click: image, text, and budget are handled 100% by AI"
            },
            percentageSectionTexts: {
              title: "Why focus on your online business today?",
              titleMobile: "Today: Focus online",
              subtitle: "Create valuable interactions with interested customers: online is where all relationships begin.",
              subtitleMobile: "Online is where all relationships begin with your customers",
              paragraphPart: {
                1: "of customers choose a restaurant based on social media",
                2: "of customers search online before booking",
                3: "of customers look for a personalized experience"
              },
              paragraphPartMobile: {
                1: "choose a restaurant based on social media",
                2: "search online before booking",
                3: "look for a personalized experience"
              },
              percentage: {
                1: "77%",
                2: "90%",
                3: "88%"
              }
            },
            pricing: {
              subtitle: "Our offer",
              title: "Clear prices and constant support:  Results equally efficient ",
              text: "A subscription without surprises. Try it for 30 days at half price.",
              info1: {
                title: "Unlimited strategic support on WhatsApp",
                text: "Direct contact with a dedicated expert on WhatsApp, to work together for the success of your place."
              },
              info2: {
                title: "Training and 1-1 consulting in video call",
                text: "For a personalized strategy or specific training, book your individual consultation in a video call."
              },
              info3: {
                title: "Access to hundreds of resources for marketing",
                text: "Discover and apply a wide selection of exclusive materials to create effective and engaging campaigns in your restaurant"
              },
              card: {
                pricing: "€220",
                text: "+ VAT per month",
                title: "Starting from",
                contact: "Contact us",
                subtitle: "PROMO: Credit for a sponsored post per month included.",
                info: {
                  title: "What's included?",
                  subtitle: "Monthly or annual plan. Always without commissions."
                }
              },
              iconlist: {
                text1: "Website with Online Menu with QR Code",
                text2: "Sponsored Facebook and Instagram posts",
                text3: "Social Calendar with hundreds of graphics",
                text4: "Artificial Intelligence for Marketing",
                text5: "Digital customer list and Mail marketing Automation",
                text6: "EXTRA: +€40/month for reservations from your website",
                text7: "EXTRA: +€40/month for delivery and takeaway from the website"
              },
              iconlistMobile: {
                text1: "Website with Online Menu",
                text2: "Sponsored Social posts",
                text3: "Social Calendar with graphics",
                text4: "AI for Marketing",
                text5: "Customer list and Mail Automation",
                text6: "EXTRA: +€40/month for reservations from your website",
                text7: "EXTRA: +€40/month for delivery and takeaway from the website"
              }
            },
            featuresTeam: {
              subtitle: "Who we are",
              title: "The Restaurants Club team",
              text: "We are a group of young entrepreneurs who want to enhance the world of restaurant industry. We are an innovative startup. We are developers, data scientists, marketing experts, trainers, and consultants.",
              textMobile: "We are young entrepreneurs who want to enhance the restaurant industry. We are an innovative startup.",
              info1: {
                title: "Our vision",
                text: "To democratize customer acquisition and loyalty technologies, making them accessible to all players in the sector.",
                textMobile: "To democratize customer acquisition and loyalty technologies."
              },
              info2: {
                title: "Our mission",
                text: "To support restaurant industry activities in communicating and spreading their unique interpretation of food and wine culture.",
                textMobile: "To support restaurant industry activities in communicating their unique interpretation of enogastronomic culture."
              }
            },
            freetrialCardTexts: {
              title: "Try it 30 days at half price.",
              subtitle: "INCLUDES: setup + first sponsored post + 4 strategic consultations",
              button: {
                1: "WhatsApp Chat",
                2: "Discover the details"
              }
            },
            faq: {
              subtitle: "Everything you need to know",
              title: "Frequently Asked Questions",
              question: {
                1: "Where are the offices of Restaurants Club?",
                2: "Is Restaurants Club a management software?",
                3: "Is Restaurants Club a delivery or online reservation app?",
                4: "Do I have to use the Restaurants Club software personally?",
                5: "What is the duration of the contractual commitment with Restaurants Club?",
                6: "Will I receive assistance and support from Restaurants Club?"
              },
              answer: {
                1: "We are in Milan, Pisa, and Naples. We operate throughout the Italian territory.",
                2: "No, Restaurants Club is Automated Marketing Software. We have integrations with various cash management systems to simplify your daily work.",
                3: "Also. Although the core business is the effective management of marketing, the software has a delivery and online booking add-on. Your customers can order takeaway, have the order delivered, or book a table directly from your site.",
                4: " Who are we to tell you what to do! The software is designed to make marketing management easier for you, so ideally the highest value appreciation is when using Restaurants Club yourself. However, if you think you can entrust this task to a trusted collaborator, it is absolutely possible. The important thing is to remain the administrator of your sensitive data.",
                5: "You can commit to Restaurants Club for a duration of 1 month or 1 year. If you choose the monthly option, you can renew the contract month by month on your own. You can indeed cancel your subscription at any time, we just ask for 15 days' notice. If you choose the annual option, we ask you to commit with us for at least 6 months. After this period, you can cancel your subscription. With the annual contract, you will benefit from a discount relative to the price of a single month.",
                6: "Constantly. Our customer care service will follow you step by step, from setting up the software to providing you with strategic advice on marketing and promotion. Then there are the specialized consultants, video courses, etc. In short, you are well-covered!"
              }
            },
            footer: {
              subtitle: "The artificial intelligence software to engage customers who love what you do",
              aitool: {
                title: "Free AI Tools",
                1: "Position Analysis",
                2: "Posts for your socials",
                3: "Typical Customer Analysis",
                4: "Examples of Offers",
                5: "Restaurant Ranking"
              },
              info: {
                title: "Info",
                1: "Privacy Policy",
                2: "Cookie Policy",
                3: "Ethical Code"
              },
              copyright: "© 2024 Restaurants Club is a SaaS by AiGot Srl. VAT ID: 11498080966 - Capital: €110,000. All rights reserved."
            },
            contact: {
              subtitle: "Contacts",
              title: "Let's have a chat?",
              text: "Our team is available for you.",
              info1: {
                title: "Write to the team!",
                subtitle: "Doubts or questions? We are here!",
                text: "info@restaurants.club"
              },
              info2: {
                title: "Chat on WhatsApp",
                subtitle: "Support available 24/7",
                text: "+39 334 105 3511"
              },
              info3: {
                title: "Come visit us",
                subtitle: "We look forward to seeing you at our office!",
                text: "Via Santa Maria, 25, 56126 Pisa PI"
              },
              info4: {
              title: "Call us",
              subtitle: "Mon-Fri from 9 am to 6 pm.",
              text: "+39 050 098 5159"
              }
            },
            hubspots: {
              subtitle: "Contact us",
              title: "Would you like to know more?",
              text: "We can't wait to answer your questions.",
              loading: "Loading ..."
            }
          }
        }  ,  
      it: {
        translation: {
          navBarMenuVoices: [
            {
              name: "Home",
            },
            {
              name: "Prodotto",
            },
            {
              name: "Blog",
            },
            {
              name: "Contatti",
            },
            {
              name: "Prezzi",
            }
          ],
          changeLanguage: "Cambia lingua",
          languages: [
            {
              language: "Italiano",
              lng: "it",
              icon: italianLanguageIcon
            },
            {
              language: "Inglese",
              lng: "en",
              icon: englishLanguageIcon
            }
          ],
          navBarButton: {
            login: "Accedi",
            onBoarding: "Entra nel Club",
            dropDownMenu: "Drop down menu",
            dropDownMenuLinks: [
              {
                name: "Strumenti AI Gratis",
                link: "aiTools"
              }
            ]
          },
          contactsPageTexts: {
            preTitle: "Parliamone, contattaci!",
            title: "Restaurants club fa davvero per te?",
            paragraphAfterTitle: "Scopriamolo insieme! Invia una mail, scrivi in chat o compila il form: siamo ovunque!",
            titleParagraph1: "Inviaci un’email",
            paragraph1: "Ci siamo dal lunedì al venerdì",
            linkParagraph1: "info@restaurants.club",
            titleParagraph2: "Chatta con Ciccio",
            paragraph2: "Il nostro bot risolve i tuoi dubbi h24",
            linkParagraph2: "Inizia a chattare",
            titleParagraph3: "Passa dall’ufficio",
            paragraph3: "Vienici a trovare quando vuoi",
            linkParagraph3: "Via Santa Maria 25, 56126 Pisa",
            titleParagraph4: "Telefonaci",
            paragraph4: "Dal lunedì al venerdì 9-18"
          },
          heroProductsSectionTexts: {
            bannerNews: "Novità!",
            paragraphBanner: "Abbiamo una nuova interfaccia grafica, ancora più semplice",
            title: "Fai marketing in modo semplice",
            paragraphPart1: "Con Restaurants Club, il tuo ristorante può raggiungere una visibilità straordinaria, senza la necessità di affidarti a freelancer o agenzie. È il software che ti permette di fare marketing in completa autonomia. ",
            button: "Inizia ora",
            button2: "Scopri di più"
          },
          weKnowItSectionTexts: {
            title: "Noi lo sappiamo",
            titleParagraph1: "Non puoi farti in 4",
            paragraph1: "Fai già il ristoratore, non puoi fare anche il Social Media Manager, Il Digital Marketer e il PR Specialist. Devi fare marketing in modo efficace senza perdere tempo.",
            titleParagraph2: "Chi conosce il tuo ristorante meglio di te?",
            paragraph2: "Le sfide superate, le fonti di ispirazione: chi meglio di te può raccontare la storia del tuo ristorante? Ecco, è per questo che un'agenzia non può farlo al posto tuo!",
            titleParagraph3: "Non puoi lavorare solo il weekend",
            paragraph3: "Lo afferma FIPE: i ristoratori realizzano il 62% dei ricavi tra il venerdì e il sabato. Invece, con Restaurants Club, attiri clienti tutti i giorni."
          },
          forRestaurateursSectionTexts: {
            title: "Dai ristoratori per i ristoratori",
            titleParagraph1: "Anni di feedback e pareri",
            paragraph1: "Sono trascorsi diversi anni dall'inizio di questo percorso, durante i quali abbiamo ricevuto numerosi feedback dai nostri clienti che ci hanno permesso di migliorare costantemente il software.",
            titleParagraph2: "Ci sono venuti a trovare direttamente a casa",
            paragraph2: "Abbiamo avuto il piacere di accogliere Matteo e Daniele, proprietari di due ristoranti con caratteristiche molto diverse, che hanno offerto un prezioso contributo allo sviluppo della piattaforma."
          },
          featuresSectionTexts: {
            title: "Come fidelizzare i clienti",
            titleParagraph1: "Sito web",
            paragraph1: "Ricevi un sito in poche ore, già ottimizzato per uscire tra i primi risultati. Non ti basta? Puoi modificarlo tu stesso!",
            list1Item1: "Menu digitale che aggiorni in un attimo",
            list1Item2: "Modifica in autonomia testi e immagini",
            list1Item3: "Ottimizzazione per scalare i motori di ricerca",
            titleParagraph2: "Pubblicità",
            paragraph2: "Fare pubblicità efficace è complicato se non sei un esperto. Con Restaurants Club puoi lanciare ADS in tre click. Come? Alla parte difficile pensiamo noi.",
            list2Item1: "+ 51 mila utenti raggiunti in meno di 2 mesi",
            list2Item2: "Porti al ristorante solo i tuoi clienti ideali",
            list2Item3: "Tieni sotto controllo il marketing",
            titleParagraph3: "Social media",
            paragraph3: "Poco tempo per postare? Con Restaurants Club puoi contare su centinaia di modelli grafici che modifichi come vuoi. La vera chiccha? Il generatore di testi automatico",
            list3Item1: "+ 820 visualizzazioni in meno di 2 mesi",
            list3Item2: "Testi suggeriti per le didascalie dei post",
            list3Item3: "Modelli grafici personalizzabili"
          },
          reviewsSectionTexts: {
            review1: "Un sito molto dinamico con un centro assistenza che funziona e soprattutto attento alle esigenze del cliente... Consigliatissimo per chi vuole lavorare nel settore ristorazione e risolvere in tempo reale qualsiasi situazione.",
            name1: "Pasquale",
            review2: "Dal sito creato su misura al programma che ti permette di aggiornare in tempo reale il menù,creare e sviluppare marketing a proprio piacimento, ma per fortuna con i consigli e la consulenza costante di uno staff preparato. Di certo la mia esperienza migliore con promotori di siti e pubblicità.",
            name2: "Cosimo",
            review3: "Sito creato molto bene e di grande effetto per la ristorazione. Personale sempre disponibile per qualsiasi evenienza. Lo consiglio.",
            name3: "Paolo Truglio",
            review4: "Esperienza fantastica utile e chiara! Avvincente e coinvolgente. Lo staff di restaurants club.. eccezionale!",
            name4: "Luciano Metastasio",
            review5: "Azienda composta da ragazzi giovani, in gamba, sempre al passo! Ci stiamo trovando benissimo con loro, dalla creazione del sito web, alle campagne pubblicitarie, al contatto costante. Sono sempre a disposizione per qualsiasi dubbio o proposta, sempre pronti a trovare il modo di soddisfare ogni richiesta!",
            name5: "Rigels Turkeshi",
            review6: "Restaurants club è stata una grandissima scoperta, gestita ad hoc da tanti ragazzi capaci e con tanta voglia di fare, di crescere e di “far” crescere. Il servizio è sempre in espansione, pieno di novità che mirano alla miglioria di un servizio già di per sé assolutamente completo ma comunque pronti ad ascoltare ogni esigenza da parte del cliente...",
            name6: "Emanuele, Primo Pasta e Street food",
            review7: "Restaurants club nel suo insieme ha reso possibile l'attivazione di ogni mia necessità nel campo dei social, ha non solo avviato il tutto ma lo staff che ne fa parte se ne prende cura in ogni sua sfaccettatura , sono veramente l'eccellenza la disponibilità la cordialità fatta persona che in ogni ambito lavorativo sono le basi principali ...",
            name7: "Federica La mantia",
            review8: "Serietà e gentilezza. Questa piattaforma ci sta aiutando ad incrementare il nostro lavoro raggiungendo più clienti. Siamo molto soddisfatti di restaurant club. Francesca, la nostra referente è seria e competente. La sua guida rende la gestione del sito meno complicato di quello che pensavamo.",
            name8: "Andy Perini",
            review9: "Questa piattaforma di marketing per la ristorazione è semplice anche grazie ai fantastici ragazzi che ti seguono in ogni aspetto della campagna. Grazie a l'utilizzo di strumenti intuitivi e un supporto clienti affidabile, è la scelta ideale per promuovere il proprio ristorante. Consigliatissimo!",
            name9: "Monique",
            review10: "Un sito ben progettato e intuitivo, perfetto per chi opera nel settore della ristorazione. La piattaforma offre una vasta gamma di strumenti utili per gestire al meglio il proprio business. Inoltre, il supporto clienti è sempre disponibile e altamente competente, pronto a fornire assistenza in ogni momento. È un ottimo alleato per chi cerca di ottimizzare le proprie operazioni e migliorare l’esperienza del cliente!",
            name10: "Giovanni"
          },
          faqSectionTexts: {
            title: "Domande frequenti",
            question1: "Cos’è Restaurants Club?",
            answer1: "Restaurants Club è un software che permette ai ristoratori di curare a tutto tondo la presenza online del proprio ristorante. In modo semplice ed efficace consente di creare un sito web ottimizzato, lanciare campagne marketing in pochi click, gestire i canali social, le prenotazioni ed effettuare un servizio di delivery senza intoppi. In breve, Restaurants Club è uno ma fa per mille: sito, pubblicità, gestione sala e delivery. Tutto il necessario per il successo di un ristorante ma in un unico strumento.",
            question2: "Quanto costa Restaurants Club?",
            answer2: "Il costo di Restaurant Club varia in base all'impegno mensile o annuale e al numero di Moduli extra scelti.",
            question3: "Cos’è il credito pubblicitario? Come posso spenderlo?",
            answer3: "Il credito pubblicitario consiste in un credito monetario spendibile per fare campagne pubblicitarie online sulle piattaforme social Facebook e Instagram. La gestione e il lancio di queste campagne deve essere effettuato all’interno della piattaforma Restaurants Club per beneficiare del credito. Il credito pubblicitario incluso nel tuo abbonamento deve essere speso sempre entro la scadenza del contratto. Ti verrà erogato all’attivazione dell’abbonamento e, nel caso di abbonamento annuale, di mese in mese.",
            question4: "Qual è la frequenza dei pagamenti?",
            answer4: "I pagamenti sono sempre mensili, anche in caso di impegno annuale.",
            question5: "Quale metodi di pagamento accettate?",
            answer5: "I metodi di pagamento accettati. Carte di credito/debito tramite account Stripe, RID Bancario o Bonifico Bancario;",
            titleBlueParagraph: "siamo per i software con dietro le persone",
            blueParagraph: "Alle redini della nostra piattaforma ci sono Francesca, Charlotte, Denise e molti altri. Se hai bisogno di aiuto, uno dei nostri è pronto a seguirti passo passo. Anche di notte con l'assistenza via Whatsapp."
          },          
          heroHeaderSectionTexts: {
            title: "Porta facce nuove al tuo ristorante e conquistale",
            paragraphPart1: "Fai del tuo ristorante il luogo ideale dei tuoi clienti. Restaurants Club è un software facile con cui spargi la voce, fai colpo e conquisti.",
            privacyPart1: "Trattiamo i tuoi dati con cura.",
            privacyPart2: "Vedi la",
            privacyLink: "Privacy policy",
            button: "Entra nel club",
            button2: "Chiedi più info",
            button3: "Vai online",
            button4: "Inizia ora",
            paragraphPart1mobile: "Il software intuitivo che potenzia la tua attività. Consulenza strategica inclusa. Inizia ora in autonomia.",
          },
          socialProofSection: {
            title: "Ecco chi ci ha scelto:",
            titlemobile: "Ecco chi ci ha scelto:",
          },
          socialProofSection2: {
            title: "I nostri partner"
          },
          featuresSectionTextsFirstPart: {
            title: "Ottimizza l’attività sui social con l’intelligenza artificiale",
            paragraph: "Raggiungi clienti interessati e ottimizza la tua presenza online con strumenti intelligenti e intuitivi.",
            firstFeature: "Sponsorizzate su Facebook e Instagram ottimizzate",
            secondFeature: "Grafiche accattivanti per la pubblicità",
            thirdFeature: "Crea post social con l’Intelligenza Artificiale",
            button: "Scopri i dettagli"
          },
          featuresSectionTextsSecondPart: {
            title: "Gestisci menù, prenotazioni e ordini online dal tuo sito",
            paragraph: "Dal tuo sito web i tuoi clienti possono prenotare, ordinare e vedere il tuo menù digitale. Dominio e Hosting inclusi nell'abbonamento",
            firstFeature: "Menù digitale con QR Code",
            secondFeature: "Prenotazioni online senza commissioni",
            thirdFeature: "Delivery, Asporto, E-commerce senza commissioni",
            button: "Scopri i dettagli"
          },
          featuresSectionTextsThirdPart: {
            title: "Ottieni il massimo dai dati dei clienti",
            paragraph: "Valorizza ogni interazione con i tuoi clienti: sfrutta la potenza dei dati per offrire esperienze personalizzate.",
            firstFeature: "Agenda clienti connessa con sito e sistema per newsletter",
            secondFeature: "Importa ed esporta i dati dei clienti con file CSV",
            thirdFeature: "Mail automatiche per promemoria, recensioni, acquisti etc",
            button: "Scopri i dettagli"
          },
          testimonialSectionTexts: {
            review: "“Questa piattaforma ci sta aiutando ad incrementare il nostro lavoro raggiungendo più clienti. Siamo molto soddisfatti di Restaurants Club.”",
            reviewmobile: "“Ci sta aiutando ad incrementare il nostro lavoro raggiungendo più clienti. Siamo molto soddisfatti.”",
            reviewer: "Andy Perini",
            reviewerSpecification: "Titolare di Andy Pizza, Pizzeria in provincia di Roma"
          },
          reviewSectionTexts: {
            review: "“Programma social, pubblicazioni, prenotazioni ed ordini davvero ben curati ma più di tutto avere la possibilità di un eCommerce e di poter caricare ogni cliente nel proprio database sono nei nostri giorni fondamentali per far crescere le nostre attività.”",
            reviewer: "— Emanuele Caiazza",
            reviewerSpecification: "Titolare di Primo Pasta, ristorante a Pisa"
          },
          socialSectionTexts: {
            title: "Raggiungi clienti interessati al tuo locale con le sponsorizzate sui social",
            titleMobile: "Raggiungi interessati con le sponsorizzate",
            subtitle: "La nostra funzione più amata!",
            paragraphPart: "Crea pubblicità efficaci in pochi click: scegli il segmento di utenti che vuoi coinvolgere, seleziona o carica un’immagine, descrizione e budget sono già suggeriti per te con l’intelligenza artificiale",
            paragraphPartMobile: "Pubblicità efficaci in un click: immagine, testo e budget sono già pronti con l'AI"
           },
          percentageSectionTexts: {
            title: "vai online in 3 step",
            titleMobile: "vai online in 3 step",
            subtitle: "Crea interazioni di valore con clienti interessati: online è dove oggi tutte le relazioni possono iniziare.",
            subtitleMobile: "Online è dove oggi tutte le relazioni con i tuoi clienti possono iniziare",
            paragraphPart: {
              1: "Registrati per avere il tuo sito web e la tua dashboard",
              2: "Crea il tuo menù e il tuo sito e personalizza ogni dettaglio",
              3: "Fai partire la tua prima campagna pubblicitaria sui social",
            },
            paragraphPartMobile: {
              1: "Registrati per avere il tuo sito web e la tua dashboard",
              2: "Crea il tuo menù e personalizza il tuo sito",
              3: "Fai partire la tua prima campagna pubblicitaria sui social",
            },
        },
        pricing: {
          subtitle: "La nostra offerta",
          title: "Non ti lasciamo da solo",
          text: "Assistenza 24/24, strategia ad hoc e accademia. Ti abbiamo già detto che ci piacciono i software con dietro le persone?",

          info1: {
            title: "Assistenza via Whatsapp 7/24",
            text: "È il nostro assistente virtuale, sempre pronto a risolvere i tuoi dubbi su WhatsApp. Scrivigli quando vuoi!",
          },
          info2: {
            title: "Una strategia apposta per te",
            text: "Tra le tante possibilità, ci distinguiamo con soluzioni  pensate apposta per te.",
          },
          card: {
            pricing: "€ 220 ",
            text: "+ IVA al mese",
            title: "Un solo piano, tutto incluso",
            subtitle: "Credito per una sponsorizzata al mese incluso.",
            contact: "Vai online",
            info: {
              title: "Cosa include?",
              subtitle: "Piano mensile o annuale. Sempre senza commissioni.",
            },
          },
          iconlist: {
            text1: "Sito web con Menù Online con QR Code",
            text2: "Pubblicità su Facebook e Instagram",
            text3: "Calendario Social con grafiche personalizzabili",
            text4: "Consulenza marketing dedicata",   
          },
          iconlistMobile: {
            text1: "Sito web con Menù Online con QR Code",
            text2: "Pubblicità su Facebook e Instagram",
            text3: "Calendario Social con grafiche personalizzabili",
            text4: "Consulenza marketing dedicata",
          },
        },
        featuresTeam: {
          title: "siamo per i software con dietro le persone",
          text: "Alle redini della nostra piattaforma ci sono Francesca, Charlotte, Denise e molti altri. Se hai bisogno di aiuto, uno dei nostri è pronto a seguirti passo passo. Anche di notte con l'assistenza via Whatsapp.",
          textMobile: "Alle redini del nostro software ci sono Francesca, Pablo, Marco, e molti altri. Se hai bisogno di aiuto, uno dei nostri è pronto a seguirti passo passo. Anche di notte con l'assistenza via Whatsapp.",
          info1: {
            title: "La nostra visione",
            text: "Democratizzare le tecnologie di acquisizione e fidelizzazione dei clienti, rendendole accessibili a tutti gli attori nel settore.",  
            textMobile: "Democratizzare le tecnologie di acquisizione e fidelizzazione dei clienti.",
          },
          info2: {
            title: "La nostra missione",
            text: "Supportare le attività di ristorazione nel comunicare e diffondere la loro interpretazione unica della cultura enogastronomica. ",
            textMobile: "Supportare le attività di ristorazione nel comunicare la loro interpretazione unica della cultura enogastronomica. ",
          }
        },
        freetrialCardTexts: {
          title: "Un'agenzia marketing in tasca",
          subtitle: "Con Restaurants Club hai tutto sotto controllo. Puoi aggiornare il tuo sito all'istante, creare contenuti social perfetti e lanciare campagne in pochi click! Scopri di più su come fare marketing in modo veloce e senza stress",
          button: {
            1: "Chat WhatsApp",
          }
        },
        faq: {
          subtitle: "Tutto quello che devi sapere",
          title: "Domande Frequenti",
          question: {
            1: "Dove sono gli uffici di Restaurants Club?",
            2: "Restaurants Club è un gestionale?",
            3: "Restaurants Club è un App di delivery o prenotazioni online?",
            4: "Devo utilizzare personalmente il software Restaurants Club?",
            5: "Qual è la durata dell'impegno contrattuale con Restaurants Club?",
            6: "Riceverò assistenza e supporto da Restaurants Club?",
          },
          answer: {
            1: "Siamo a Milano, Pisa e Napoli. Operiamo su tutto il territorio italiano. ",
            2: "No, Restaurants Club è un software di Marketing Automatizzato. Abbiamo integrazioni con diversi gestionali di cassa per semplificare il tuo lavoro quotidiano.",
            3: "Anche. Sebbene il core business sia la gestione efficace del marketing, il software dispone di un add-on di delivery e prenotazione online. I tuoi clienti possono ordinare da asporto, farsi recapitare l’ordine o prenotare un tavolo direttamente dal tuo sito. ",
            4: "Chi siamo noi per dirti cosa fare? Il software è pensato per renderti la gestione del marketing più semplice per cui sarebbe l’ideale se apprezzassi tu stesso l’usabilità di Restaurants Club. Tuttavia, se pensi di poter affidare questo compito a un tuo collaboratore fidato, nulla in contrario. L’importante è rimanere l’amministratore dei tuoi dati sensibili. ",
            5: "Puoi impegnarti con Restaurants Club per la durata di 1 mese o di 1 anno. Se scegli il mensile, puoi rinnovare il contratto di mese in mese in totale autonomia. Puoi infatti annullare il tuo abbonamento in qualsiasi momento, ti chiediamo solo un preavviso di 15 giorni. Se scegli l’annuale, ti chiediamo di impegnarti con noi per almeno 6 mesi. Dopo questo periodo puoi annullare il tuo abbonamento. Con il contratto annuale beneficierai di uno sconto relativo rispetto al prezzo del singolo mese.",
            6: "Costantemente. Il nostro servizio di customer care ti seguirà passo passo, dal setting del software fino a fornirti consigli strategici in materia di marketing e promozione. Poi ci sono i consulenti specializzati, i video corsi, ecc. Insomma, hai le spalle coperte!",
          }
        },
        footer: {
          subtitle: "Il software di intelligenza artificiale per coinvolgere clienti che amano ciò che fai",
          aitool: {
            title: "Strumenti AI Gratis",
            1: "Analisi Posizione",
            2: "Post per i tuoi social",
            3: "Analisi Cliente Tipo",
            4: "Esempi di Offerte ",
            5: "Classifica Ristoranti",
          },
          info: {
            title: "Info",
            1: "Privacy Policy",
            2: "Cookie Policy",
            3: "Codice Etico",
            4: "Termini e Condizioni",
          },
          copyright: "© 2024 Restaurants Club è un SaaS di AiGot Srl. P. IVA: 11498080966 - Capitale Sociale: 110.000€. Tutti i diritti riservati.",
        },
        contact: {
          subtitle: "Metti alla prova Ciccio, ora!",
          title: "Metti alla prova Ciccio, ora!",
          text: "Anche Ciccio adora le strategie su misura. Infatti, ha ideato dei percorsi interattivi apposta per te.",
          info1: {
            title: "8 Idee da pubblicare sui Social",
            text: "Provalo su WhatsApp",
          },
          info2: {
            title: "Analisi posizione locale",
            text: "Provalo su WhatsApp",
          },
          info3: {
            title: "Conosci davvero i tuoi clienti?",
            text: "Provalo su WhatsApp",
          },
          info4: {
            title: "Consigli per una tua sponsorizzata",
            text: "Provalo su WhatsApp",
          },

        },
           buyersTexts: {
            title: "siamo perfetti per te se...",
            titleMobile: "siamo perfetti per te se...",
            paragraphPart: {
              1: "Sei stufo delle agenzie",
              2: "Non hai tempo da perdere",
              3: "Pensi di meritare di più"
            },
            subtitle: {
              1: "Vuoi metterti alla prova e far vedere a tutti quanto sei bravo a valorizzare il tuo ristorante sui tuoi canali.",
              2: "Sai bene quant'è importante fare marketing. È per questo che vuoi farlo senza togliere tempo al tuo lavoro.",
              3: "Nel tuo ristorante hai messo tutto te stesso. Ora aspiri a farlo diventare un brand o desideri aprirne di nuovi."
            }
          },

        hubspots: {
          title: "Se sei arrivato fin qui, vuol dire che siamo quelli giusti.",
          text: "Se sei interessato, lasciaci i tuoi dati e ti contatteremo al più presto per fornirti tutte le informazioni di cui hai bisogno.",
          loading: "Caricamento ..."
        },

      },
    },
  },
    lng: 'it',
    fallbackLng: 'en',
    interpolation: {
    escapeValue: false,
  },
  });
export default i18n;
