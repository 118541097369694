import React, { useEffect, useState } from 'react';
import ldClient from './components/launch-darkly-client';
import { NavBar } from './components/navbar/navbar';
import { FreeTrialBanner } from './components/home/free-trial-card/free-trial-banner';
import { Footer } from './components/footer/footer';
import { CookieBanner } from './components/cookie-bar/cookie-banner';
import { GoogleTagManager } from './components/google-tag-manager';
import { HomePageComponent } from './components/home/home';
import { Prodotto } from './components/prodotto/prodotto';
import { Routes, Route, Navigate } from 'react-router-dom'; 
import { ContactsPageComponent } from './components/contacts/contacts';
import { AIToolsPageComponent } from './components/ai-tools/ai-tools';
import { HamburgerMenu } from './components/hamburger-menu/hamburger-menu';
import { ScrollToTop } from './components/reusable/scrollToTop';
import { PricesPageComponent } from './components/prices/prices';
import { useTranslation } from 'react-i18next';
import { SumUp } from './components/sumup-landing/sumup';

type MenuVoice = {
  name: string;
};

type DropDownLinks = {
  name: string;
  link: string;
};

export const App: React.FC = () => {
  const [flags, setFlags] = useState<{ [key: string]: boolean }>({});
  const { t } = useTranslation();

  useEffect(() => {
    const handleFlags = () => {
      const allFlags = ldClient.allFlags();
      setFlags(allFlags);
    };
    ldClient.on('ready', handleFlags);
    ldClient.on('change', handleFlags);

    return () => {
      ldClient.off('ready', handleFlags);
      ldClient.off('change', handleFlags);
      ldClient.close();
    };
  }, []);

  const menuVoices = t("navBarMenuVoices", { returnObjects: true }) as MenuVoice[];
  const dropDown = t("navBarButton.dropDownMenuLinks", { returnObjects: true }) as DropDownLinks[];

  return (
    <>
      <GoogleTagManager />
      <CookieBanner />
      <NavBar />
      <HamburgerMenu />
      <ScrollToTop />
      <div className='w-full mx-auto mt-[100px]'>
        {/*To avoid the redirect. TODO: Solve in the future */}
        <Routes>
          <Route path="/" element={<Navigate to={`/${menuVoices[0].name.toLocaleLowerCase()}`} />} /> 
          <Route path={`/${menuVoices[0].name.toLocaleLowerCase()}`} element={<HomePageComponent flags={flags} />} />
          <Route path={`/${menuVoices[1].name.toLocaleLowerCase()}`} element={<Prodotto flags={flags} />} />
          <Route path={`/${menuVoices[3].name.toLocaleLowerCase()}`} element={<ContactsPageComponent flags={flags} />} />
          <Route path={`/${menuVoices[4].name.toLocaleLowerCase()}`} element={<PricesPageComponent flags={flags} />} />
          <Route path={`/${dropDown[0].link}`} element={<AIToolsPageComponent flags={flags} />} />
          <Route path="/sumup" element={<SumUp flags={flags} />} />
        </Routes>
      </div>
      <div className='w-full'>
        <FreeTrialBanner />
        <Footer />
      </div>
    </>
  );
};
