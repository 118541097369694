import { useTranslation } from "react-i18next";
import bgIconImg from '@images/bg-for-icon-weKnow.svg';
import iconImg from '@images/icon-weKnow.png';

export const WeKnowIt = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 className="font-poppins text-gray900 text-display-lg xsm:text-display-md sm:text-display-md md:text-display-md xmd:text-display-md leading-snug text-center">
        Prova Restaurants Club <span className="font-bold">gratis</span>
      </h2>

      <div className="bg-white border w-full border-gray-200 rounded-xl shadow-md p-6 mt-6 max-w-lg mx-auto text-gray-800">
        <div className="text-xs font-semibold bg-gray-100 text-gray-700 px-4 py-1 rounded-full inline-block uppercase tracking-wide">
          Offerta riservata ai clienti SumUp
        </div>

        <div className="mt-4 text-center">
          <div className="text-4xl font-bold text-primary">
            €0 <span className="line-through text-gray-400 text-xl">€250</span>
          </div>
          <div className="text-sm text-gray-500">per 15 giorni con 30€ di Credito pubblicitario incluso</div>
        </div>

        <div className="mt-6">
          <h3 className="text-gray-900 font-semibold mb-2">Cosa include?</h3>
          <ul className="space-y-2">
            <li className="flex items-center">
              <span className="text-green-500 mr-2">✔️</span> Sito web con menù online e QR Code
            </li>
            <li className="flex items-center">
              <span className="text-green-500 mr-2">✔️</span> Pubblicità su Facebook e Instagram
            </li>
            <li className="flex items-center">
              <span className="text-green-500 mr-2">✔️</span> Editor grafico e calendario social
            </li>
            <li className="flex items-center">
              <span className="text-green-500 mr-2">✔️</span> Customer care e consulenza via WhatsApp
            </li>
          </ul>
        </div>

        <div className="mt-6 text-center">
          <button className="bg-primary text-white font-bold py-2 px-4 rounded-lg w-full">
            Provalo gratis
          </button>
          <a
            href="#"
            className="block mt-2 text-sm text-primary underline font-medium"
          >
            Contatta le vendite
          </a>
        </div>
      </div>
    </>
  );
};
