import { Form } from "./hubspot-from";
import { FeaturesSection } from "./features-section/features-section";
import { HeroProducts } from "./hero-products-section/hero-products";
import { WeKnowIt } from "./we-know-it/we-know-it";

export const SumUp: React.FC<{ flags: { [key: string]: boolean } }> = ({ flags }) => {
  return (
    <>
      <div className='w-full flex flex-col items-center gap-32 md:gap-16 sm:gap-16 xsm:gap-16'>
        <HeroProducts />
        <WeKnowIt />
        <FeaturesSection />
          <Form />
        </div>
    </>
  );
};
