import { useTranslation } from "react-i18next";
import { Button } from "../../reusable/button";
import { BannerNews } from "../../reusable/banner-news";
import MacbookPro16mockup from "../../../assets/images/MacbookPro16mockup.png";

export const HeroProducts = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-col items-center gap-4 px-20 max-w-[1634px] md:px-4 sm:px-4 xsm:px-4 bg-#F3F3F3">
      <h1 className="font-poppins text-gray900 text-display-lg xsm:text-display-md sm:text-display-md md:text-display-md xmd:text-display-md leading-snug text-center">
        Il Marketing facile per <span className="font-bold">ristoratori</span>
      </h1>
      <p className="text-gray900 text-lg xsm:text-lg sm:text-lg md:text-lg font-regular font-poppins text-center">
        Qr code e sito, post sui social e Meta ads{" "}
        <span className="font-bold">con un solo software</span>
      </p>
      <div className="flex w-full justify-center gap-3 md:flex-col sm:flex-col xsm:flex-col">
        <button className="text-gray900 underline bg-transparent border-none text-lg font-poppins cursor-pointer">
          Contatta le vendite
        </button>
        <button className="bg-white text-gray900 border border-gray300 rounded-lg px-4 py-2 text-lg font-poppins cursor-pointer hover:bg-gray100">
          Entra nel club
        </button>
      </div>
      <img className="" src={MacbookPro16mockup} alt="Icon WhatsApp" />
    </div>
  );
};
