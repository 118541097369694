import { Accordion } from "../../reusable/accordion";
import { Button } from "../../reusable/button";
import { useTranslation } from "react-i18next";
import avatar1 from "@images/avatar1.png"
import avatar2 from "@images/avatar2.png"
import avatar3 from "@images/avatar3.png"

export const FaqSection = () => {
  const { t } = useTranslation();
  const faqs = [
    {
      question: t("faqSectionTexts.question1"),
      answer: t("faqSectionTexts.answer1"),
    },
    {
      question: t("faqSectionTexts.question2"),
      answer: t("faqSectionTexts.answer2"),
    },
    {
      question: t("faqSectionTexts.question3"),
      answer: t("faqSectionTexts.answer3"),
    },
    {
      question: t("faqSectionTexts.question4"),
      answer: t("faqSectionTexts.answer4"),
    },
    {
      question: t("faqSectionTexts.question5"),
      answer: t("faqSectionTexts.answer5"),
    }
  ];

  return (
    <div id="faqs" className="w-full px-20 flex flex-col items-center md:px-4 sm:px-4 xsm:px-4">
      <h3 className="text-gray900 text-center mb-6 text-display-lg xsm:text-display-md sm:text-display-md md:text-display-md xmd:text-display-md font-bold font-brevia leading-snug uppercase">
        {t("faqSectionTexts.title")}
      </h3>
      <div className="w-full flex justify-center">
        <div className="w-full max-w-[1239px]">
          <Accordion faqs={faqs} />
        </div>
      </div>
      <div className="relative bg-darkblue700 flex flex-col items-center py-8 rounded-2xl gap-8 w-full max-w-[1634px] px-4">
        <div className="relative flex">
          <img className="absolute -left-[35px] -bottom-[5px]" src={avatar3} alt="Avatar" />
          <img className="relative z-10" src={avatar1} alt="Avatar" />
          <img className="absolute -right-[35px] -bottom-[5px]" src={avatar2} alt="Avatar" />
        </div>
        <div className="max-w-[820px] flex flex-col items-center">
          <h3 className="font-poppins text-white font-semibold text-xl text-center">
            {t("faqSectionTexts.titleBlueParagraph")}
          </h3>
          <p className="font-poppins text-gray200 font-regular text-lg text-center">
            {t("faqSectionTexts.blueParagraph")}
          </p>
        </div>
        <Button text={t("heroProductsSectionTexts.button")} isColoured={true} />
      </div>
    </div>
  );
};
