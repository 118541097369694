import { useTranslation } from "react-i18next";
import bgIconImg from '@images/bg-for-icon-weKnow.svg'
import iconImg from '@images/icon-weKnow.png'

export const WeKnowIt = () => {
    const { t } = useTranslation();
    return (
          <div className="w-full flex flex-col items-center gap-4 px-20 max-w-[1634px] md:px-4 sm:px-4 xsm:px-4">
            <h2 className="text-gray900 font-brevia text-4xl font-semibold uppercase md:hidden sm:hidden xsm:hidden">
              {t("weKnowItSectionTexts.title")}
            </h2>
              <div className="relative w-full pt-16 pb-28 px-4 bg-darkblue700 flex gap-8 rounded-2xl md:flex-col md:py-4 xmd:flex-col xmd:py-4 sm:flex-col sm:py-4 xsm:flex-col xsm:py-4">
                <h2 className="text-gray200 font-brevia text-[30px] font-semibold uppercase hidden self-stretch text-center md:block sm:block xsm:block">
                  {t("weKnowItSectionTexts.title")}
                </h2>
                <div className="flex flex-col items-center gap-5">
                  <h3 className="text-white font-brevia text-display-xs font-semibold uppercase self-stretch text-center">
                    {t("weKnowItSectionTexts.titleParagraph1")}
                  </h3>
                  <p className="self-stretch text-gray200 text-center font-poppins text-lg font-medium">
                    {t("weKnowItSectionTexts.paragraph1")}
                  </p>
                </div>
                <div className="flex flex-col items-center gap-5">
                  <h3 className="text-white font-brevia text-display-xs font-semibold uppercase self-stretch text-center">
                    {t("weKnowItSectionTexts.titleParagraph2")}
                  </h3>
                  <p className="self-stretch text-gray200 text-center font-poppins text-lg font-medium">
                    {t("weKnowItSectionTexts.paragraph2")}
                  </p>
                </div>
                <div className="flex flex-col items-center gap-5">
                  <h3 className="text-white font-brevia text-display-xs font-semibold uppercase self-stretch text-center">
                    {t("weKnowItSectionTexts.titleParagraph3")}
                  </h3>
                  <p className="self-stretch text-gray200 text-center font-poppins text-lg font-medium">
                    {t("weKnowItSectionTexts.paragraph3")}
                  </p>
                </div>
              </div>
          </div>
    )
}
