import React, { useEffect, useState } from 'react';
import ldClient from '../../components/launch-darkly-client';
import { HeroHeader } from './hero-header-section/hero-header';
import { SocialProof } from './social-proof-section/social-proof';
import { Buyers } from './percentage-section/buyer-personas-section';
import { Steps } from './percentage-section/steps';
import { FreeTrial } from './free-trial-card/free-trial-card';
import { TeamFeatures } from '../../components/features-section/features-team';
import { Pricing } from "./pricing-section/pricing";
import { Form } from "../../components/hubspot-form/hubspot-from";
import { ContactOptions } from './contact-options/contact-options';
import { SocialProof2 } from './social-proof-section/social-proof-2';
import { Marquee } from './banner-scroll/banner-scroll';

export const HomePageComponent: React.FC<{ flags: { [key: string]: boolean } }> = ({ flags }) => {
  return (
    <>
      <div className='xl:max-w-screen-xl 2xl:max-w-screen-2xl mx-auto'>
        <HeroHeader />
      </div>
      <div className='w-full'>
        <Marquee />
      </div>
     {/*<div className='xl:max-w-screen-xl 2xl:max-w-screen-2xl mx-auto'>
        {flags['socialproof_homepage'] && <SocialProof />}
        {flags['benefit_principale_homepage'] && <FreeTrial />}
        {flags['buyer_personas_homepage'] && <Buyers />}
        {flags['step_registrazione_homepage'] && <Steps />}
        {flags['consulenza_homepage'] && <TeamFeatures />}
        {flags['pricing_homepage'] && <Pricing />}
        {flags['form_contatto_homepage'] && <Form />}
        {flags['contact_homepage'] && <ContactOptions />}
        {flags['partners_homepage'] && <SocialProof2 />}
      </div>*/} 
      <SocialProof />
      <FreeTrial />
      <Buyers />
      <Steps />
      <TeamFeatures />
      <Pricing />
      <Form />
      <SocialProof2 />
    </>
  );
};
