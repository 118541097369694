import React from "react";
import { TextBanner } from "../reusable/text-banner";
import { useTranslation } from "react-i18next";
import HubSpotForm from "react-hubspot-form";
import Waves from "@images/waves.png";

export const Form = () => {
  const { t } = useTranslation();

  const portalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID;
  const formId = process.env.REACT_APP_HUBSPOT_FORM_ID;

  return (
    <div id="hubspotForm" className="flex  xsm:w-[90%] sm:w-[90%] md:w-[90%] xmd:w-[85%] lg:w-[85%] xl:w-[85%] 2xl:w-[85%] mx-auto">
      <div id="formComponent" className="flex flex-col mt-20 ">
        <TextBanner
          title={t("hubspots.title")}
          paragraph={t("hubspots.text")}
        />
        <HubSpotForm
          portalId={portalId}
          formId={formId}
          loading={<div>{t("hubspots.loading")}</div>}
        />
      </div>
    </div>
  );
};
